<script setup></script><template>
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="icon">
<path id="secondary-shape" opacity="0.32" fill-rule="evenodd" clip-rule="evenodd" d="M20.9114 8.65273C19.4717 9.01488 17.7718 9.03893 16.3035 8.59038C15.6828 8.40073 15.1988 7.91718 14.9893 7.30288C14.4674 5.77255 14.3385 3.8994 14.722 2.45758C13.9279 2.43764 13.0248 2.42578 12 2.42578C8.51575 2.42578 6.43945 2.56261 5.26285 2.68957C4.39116 2.78363 3.71902 3.37405 3.5558 4.23545C3.30175 5.57633 3 8.08303 3 12.4258C3 16.7685 3.30175 19.2752 3.5558 20.6161C3.71902 21.4775 4.39116 22.0679 5.26285 22.162C6.43945 22.2889 8.51575 22.4258 12 22.4258C15.4843 22.4258 17.5606 22.2889 18.7372 22.162C19.6089 22.0679 20.281 21.4775 20.4442 20.6161C20.6982 19.2752 21 16.7685 21 12.4258C21 10.9703 20.9661 9.72098 20.9114 8.65273ZM8 13.4258C7.4477 13.4258 7 12.9781 7 12.4258C7 11.8735 7.4477 11.4258 8 11.4258H12C12.5523 11.4258 13 11.8735 13 12.4258C13 12.9781 12.5523 13.4258 12 13.4258H8ZM8 17.9258C7.4477 17.9258 7 17.4781 7 16.9258C7 16.3735 7.4477 15.9258 8 15.9258H15C15.5523 15.9258 16 16.3735 16 16.9258C16 17.4781 15.5523 17.9258 15 17.9258H8Z" class="fill-grey-500 group-[&.active]:fill-main"/>
<g id="primary-shape">
<path d="M7 16.9258C7 17.4781 7.4477 17.9258 8 17.9258H15C15.5523 17.9258 16 17.4781 16 16.9258C16 16.3735 15.5523 15.9258 15 15.9258H8C7.4477 15.9258 7 16.3735 7 16.9258Z" fill="#0516B2"/>
<path d="M7 12.4258C7 12.9781 7.4477 13.4258 8 13.4258H12C12.5523 13.4258 13 12.9781 13 12.4258C13 11.8735 12.5523 11.4258 12 11.4258H8C7.4477 11.4258 7 11.8735 7 12.4258Z" fill="#0516B2"/>
<path d="M20.9114 8.65273C19.4717 9.01488 17.7718 9.03893 16.3036 8.59038C15.6828 8.40073 15.1988 7.91718 14.9893 7.30288C14.4674 5.77253 14.3384 3.89935 14.722 2.45752C14.722 2.45752 15.9461 2.92572 18.1961 5.17572C20.4461 7.42573 20.9114 8.65273 20.9114 8.65273Z" fill="#0516B2"/>
</g>
</g>
</svg>
</template>
